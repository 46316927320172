<template>
  <div class="not-found__container" style="min-height: 100vh">
    <div class="not-found">
     <h3 class="text-center py-4">{{ $t("page_notFound") }}</h3>
      <img src="../assets/giphy.webp" alt="404 Not found Image">
      <p class="text-center py-2" v-if="loginFailed.status">{{ loginFailed.message }}</p>
      <p class="text-center py-2" v-if="loginFailed.status">Try Refreshing or Login again by Click on Login</p>
      <button v-if="loginFailed.status" type="submit" class=" text-center ed-btn__blim common-btn" @click="clearCacheAndLogin">{{ $t("comp_login--text") }}</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'NotFound',
  metaInfo: {
    title: 'Not Found',
  },
  computed: {
    loginFailed() {
      console.log('xvf', this.$store.state.auth.loginFailed);
      return this.$store.state.auth.loginFailed;
    },
  },
  methods: {
    ...mapActions({
      logoutAction: 'auth/logout',
    }),
    clearCacheAndLogin() {
      localStorage.clear();
      this.$router.push({ name: 'Auth', params: { action: 'Login' } });
    },
  },
};
</script>

<style lang="scss" scoped>
.not-found{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
